import React from "react";
import style from "./contactUs.module.scss";
import call from "../../Assets/Image/call.svg";
import location from "../../Assets/Image/location.svg";
import message from "../../Assets/Image/message.svg";
import { Formik, Field, Form, ErrorMessage } from "formik";
const ContactUs = () => {
  const handleSubmit = (values, { resetForm }) => {
    // You can perform additional actions here before submitting the form
    // console.log("Form submitted with values:", values);
    resetForm();
  };

  const validate = (values) => {
    const errors = {};

    // Manual validation for a custom rule (e.g., a minimum password length of 8 characters)
    // if (values.password && values.password.length < 8) {
    //   errors.password = "Password must be at least 8 characters long";
    // }

    if (!values.username) {
      errors.username = `Username required`;
    }
    if (!values.email) {
      errors.email = `Email required`;
    }
    if (!values.number) {
      errors.number = `Number required`;
    }

    // You can perform other custom validations here

    return errors;
  };
  return (
    <section className={`${style.wrapper}`}>
      <div className={`container`}>
        <div className={`${style.inner} row`}>
          <div className={`${style.heading} col-lg-6`}>
            <h1 className={`${style.title} ttl-2 secondary-font`}>
              Contact us now !
            </h1>
            <p>
              Please don't hesitate to get in touch with us using the following
              contact information or by filling out the contact form below
            </p>
            <ul>
              <li>
                <a href="https://g.co/kgs/PfDJqb" target="_blank" rel="noreferrer">
                  <span className={style.icon}>
                    <img src={location} alt="logo" />
                  </span>
                  <span className={style.text}>West nada, Aiswarya fancy, Guruvayur</span>
                </a>
              </li>

              <li>
                <a href="tel:+91 62382 54535" target="_blank" rel="noreferrer">
                  <span className={style.icon}>
                    <img src={call} alt="logo" />
                  </span>
                  <span className={style.text}>+91 62382 54535</span>
                </a>
              </li>

              <li>
                <a href="mail:guruvayoorshopping@gmail.com" target="_blank" rel="noreferrer">
                  <span className={style.icon}>
                    <img src={message} alt="logo" />
                  </span>
                  <span className={style.text}>guruvayoorshopping@gmail.com</span>
                </a>
              </li>
            </ul>
          </div>
          <div className={`${style.form}  col-lg-6`}>
            <Formik
              initialValues={{
                username: "",
                email: "",
                number: "",
                message: "",
              }}
              validate={validate} // Manual validation function
              onSubmit={handleSubmit}
            >
              {() => (
                <Form action="" className={`${style.form_wrapper}`}>
                  <div className={`row`}>
                    <div className={`col-lg-6`}>
                      <div className={`${style.formGroup}`}>
                        <label htmlFor="">Name *</label>
                        <Field
                          type="text"
                          placeholder="Enter name"
                          id="username"
                          name="username"
                        />
                        <ErrorMessage
                          name="username"
                          component="div"
                          className="error-message"
                        />
                      </div>
                    </div>

                    <div className={`col-lg-6`}>
                      <div className={`${style.formGroup}`}>
                        <label htmlFor="">Number *</label>
                        <Field
                          type="text"
                          placeholder="Enter Number"
                          id="number"
                          name="number"
                        />
                        <ErrorMessage
                          name="number"
                          component="div"
                          className="error-message"
                        />
                      </div>
                    </div>

                    <div className={`col-lg-12`}>
                      <div className={`${style.formGroup}`}>
                        <label htmlFor="">Email *</label>
                        <Field
                          type="text"
                          placeholder="Enter Email"
                          id="email"
                          name="email"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="error-message"
                        />
                      </div>
                    </div>

                    <div className={`col-lg-12`}>
                      <div className={`${style.formGroup}`}>
                        <label htmlFor="">Message</label>
                        <Field
                          as="textarea"
                          id="message"
                          name="message"
                          placeholder="Enter Message"
                        ></Field>
                        <ErrorMessage
                          name="message"
                          component="div"
                          className="error-message"
                        />
                      </div>
                    </div>

                    <div className={`col-lg-12`}>
                      <div className={`${style.formGroup}`}>
                        <button type="submit">Submit</button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
