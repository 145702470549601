import ProductSlider from './ProductSlider'
import style from './productDetail.module.scss'
import { useProductDetail } from './useProductDetail'
const ProductDetail = () => {
    const { data } = useProductDetail();
    // console.log(data, 'product details');

    return (
        <section>

            {/* products header just commented for now #FIXME  */}


            {/* <div className={`w-100`}>
            <div className="container">
            <ProductHeader/>
            </div>
           </div> */}



            <div className={style.root}>
                <div className={`container`}>
                    <div className={`${style.root_inner}`}>

                        {data?.image &&
                            <div className={`${style.image_wrapper}`}>
                                <ProductSlider data={data?.image} />
                            </div>
                        }


                        {
                            data &&
                            <div className={style.detail_wrapper}>


                                {
                                    data.title &&
                                    <div className={style.heading}>
                                        <h1 className={style.title}>{data.title}</h1>
                                    </div>
                                }


                                {
                                    data.price &&
                                    <div className={style.pricing}>
                                        <h2>{`₹${data.price}`}<span> Plus Shipping Charges</span><span className={style.icon}></span></h2>
                                    </div>
                                }

                                {
                                    (data.size || data?.known_as) &&
                                    <div className={style.size_and_known}>
                                        <ul>
                                            {
                                                data.size &&
                                                <li>
                                                    <h5 className={style.list_title}>Size & Details</h5>
                                                    <h3 className={style.sub_title}>{data.size}</h3>
                                                </li>
                                            }
                                            {
                                                data.known_as &&
                                                <li>
                                                    <h5 className={style.list_title}>Known As</h5>
                                                    <h3 className={style.sub_title}>{data.known_as}</h3>
                                                </li>
                                            }
                                        </ul>
                                    </div>
                                }
                                {/* {
                                    data?.color?.length !== 0 &&
                                    <div className={style.colors}>
                                        <h5 className={style.list_title}>Size & Details</h5>
                                        <ul>
                                            {
                                                data?.color && data?.color?.map((item, index) => {
                                                    return (
                                                        <li>
                                                            {item?.color &&
                                                                <span
                                                                    className={style.color}
                                                                    style={{ backgroundColor: item?.color }}>
                                                                </span>
                                                            }
                                                            {item?.colorText &&
                                                                <span
                                                                    className={style.text}>
                                                                    {item?.colorText}
                                                                </span>
                                                            }
                                                        </li>
                                                    )
                                                })
                                            }

                                        </ul>

                                    </div>
                                } */}


                                {
                                    <div className={style.action_btn}>
                                        <a href="tel:+916238254535" className={style.call}>Call Us</a>
                                        <a href="https://wa.me/+916238254535?text=Hello%20there!" className={style.whatsapp}>WhatsaApp Now</a>
                                    </div>
                                }

                                {data.description &&
                                    <div className={style.description}>
                                        <h5 className={style.list_title}>Description</h5>
                                        <h3 className={style.sub_title}>{data.description}</h3>
                                    </div>

                                }





                            </div>
                        }
                    </div>
                </div>
            </div>

            {/* <NewProducts/> */}
        </section>

    )
}

export default ProductDetail