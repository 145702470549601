import { Button, Form } from 'react-bootstrap';
import style from './testimonials.module.scss'
import { Formik } from 'formik';
import axios from 'axios';
import { useTestimonials } from './useTestimonials';
import { BASE_URL } from '../../../Config/api';

const TestimonialsCreate = ({ handleView }) => {
    const { dataEdit, idParams } = useTestimonials({ handleView });
    // console.log(dataEdit);
    return (
        <div className={style.root}>

            <div className={` `}>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        testimonials_name: dataEdit?.name ? dataEdit?.name : '',
                        testimonials_designation: dataEdit?.designation ? dataEdit?.designation : '',
                        testimonials_message: dataEdit?.message ? dataEdit?.message : '',
                        testimonials_image: dataEdit?.image ? dataEdit?.image : '',
                        testimonials_isHome: dataEdit?.isHome ? dataEdit?.isHome : 'false',

                    }}
                    validate={values => {
                        const errors = {};
                        if (!values.testimonials_name) {
                            errors.testimonials_name = 'Required';
                        }
                        if (!values.testimonials_designation) {
                            errors.testimonials_designation = 'Required';
                        }
                        if (!values.testimonials_message) {
                            errors.testimonials_message = 'Required';
                        }

                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {

                        const formData = new FormData();
                        formData.append('name', values.testimonials_name);
                        formData.append('designation', values.testimonials_designation);
                        formData.append('message', values.testimonials_message);
                        formData.append('isHome', values.testimonials_isHome);
                        // formData.append('date', Date.now());
                        for (let index = 0; index < values.testimonials_image.length; index++) {
                            formData.append('image', values.testimonials_image[index]);
                        }
                        if (!idParams) {
                            axios.post(`${BASE_URL}/testimonials`, formData).then((response) => {

                            });
                        } else {
                            axios.put(`${BASE_URL}/testimonials/${idParams}`, formData).then((response) => {

                            });
                        }




                    }}


                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue
                        /* and other goodies */
                    }) => (
                        <Form onSubmit={handleSubmit} className={`row g-1 g-sm-2`} >


                            {/* name */}
                            <Form.Group className="col-lg-6">
                                <Form.Label>Author Name</Form.Label>
                                <Form.Control
                                    name="testimonials_name"
                                    type="text"
                                    placeholder="Enter author name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.testimonials_name}
                                />
                                <span className={`errors`}>{errors.testimonials_name && errors.testimonials_name}</span>
                            </Form.Group>

                            {/* designation */}
                            <Form.Group className="col-lg-6">
                                <Form.Label>Author designation</Form.Label>
                                <Form.Control
                                    name="testimonials_designation"
                                    type="text"
                                    placeholder="Enter author designation"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.testimonials_designation}
                                />
                                <span className={`errors`}>{errors.testimonials_designation && errors.testimonials_designation}</span>
                            </Form.Group>




                            {/* message */}
                            <Form.Group className="col-12">
                                <Form.Label>Message</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="testimonials_message"
                                    rows={3}
                                    placeholder="Enter author message"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.testimonials_message}
                                />
                                <span className={`errors`}>{errors.testimonials_message && errors.testimonials_message}</span>
                            </Form.Group>



                            <Form.Group className="col-6">
                                <Form.Label>Testimonials Image</Form.Label>
                                <Form.Control
                                    type="file"
                                    placeholder="testimonials file"
                                    onChange={(e) => setFieldValue("testimonials_image", e.target.files)}
                                />
                                <span className={`errors`}>{errors.testimonials_image && errors.testimonials_image}</span>
                            </Form.Group>


                            <Form.Group className="col-6 d-flex align-items-end">
                                <Form.Check // prettier-ignore
                                    type={`checkbox`}
                                    id={`default-checkbox`}
                                    label={`Show in home page`}
                                    value={true}
                                    name={`testimonials_isHome`}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    checked={values.testimonials_isHome === 'true'}
                                />
                            </Form.Group>









                            <div className={`col-12 d-flex mt-4 b`}>
                                <Button variant="primary" type="submit" className='w-auto  px-3 py-2 text-whit ms-auto '>
                                    Submit Details +
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>

            </div>


        </div>
    )
}

export default TestimonialsCreate