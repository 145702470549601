import React from "react";
import { Link } from "react-router-dom";
import style from "./gallery.module.scss";
import Icon from "../Icon";
import bg from "../../Assets/Image/shape-gvr.svg";
import { BASE_URL } from "../../Config/api";
function Gallery({ galleryData }) {
  // console.log(galleryData);
  return (
    <>
      <div className={`${style.top_image_wrapper}`}>
        <div className={`${style.top_image}`}>
          <img loading="lazy" src={bg} alt="banner" />
        </div>
      </div>
      <div className={style.root}>
        <div className={`container`}>
          <div className={`${style.main_wrapper}`}>
            <div className={`${style.heading}`}>
              <h4 className="secondary-font ttl-1">Our gallery</h4>
              <Link className={`custom_btn light`} to={`/gallery`}>
                <span>View All</span>
                <span>
                  <Icon icon={"arrow"} color={"#fff"} size={14} />
                </span>
              </Link>
            </div>
            <div className={`row g-1 g-sm-2 pb-4`}>
              {galleryData &&
                galleryData.map((item, index) => {
                  // console.log(item, "gallerysss");
                  return (
                    <div className={`col-6 col-lg-3`} key={index}>
                      <div className={`${style.image_gallery_1}`}>
                        {/* <img
                          loading="lazy"
                          src="https://picsum.photos/1000"
                          alt="banner"
                        /> */}
                        <img
                          loading="lazy"
                          src={`${BASE_URL}/uploads/${item.image.filename}`}
                          alt="banner"
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className={`row`}>
              <div className={`col-lg-7`}>
                <div className={`${style.right_text}`}>
                  <h4 className={`ttl-2 secondary-font`}>
                    We offer meticulously crafted idols and heritage beautifully.
                  </h4>
                </div>
              </div>
              <div className={`col-lg-5`}>
                <div className={`${style.left_text}`}>
                  <p>
                    Here, you can embark on a visual journey through the countless forms of divine expression.
                  </p>
                  <Link className={`custom_btn light`} to={`/`}>
                    <span>Explore Gallery</span>
                    <span>
                      <Icon icon={"arrow"} color={"#fff"} size={14} />
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Gallery;
