import React from 'react'
import Slider from '../Slider'
import style from './banner.module.scss'
import { SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import Icon from '../Icon';
import { BASE_URL } from '../../Config/api';
import offerImage from './three.jpg'
function Banner({ data }) {
  const customSetting = {
    slidesPerView: 1,
    spaceBetween: 0
  }
  return (
    <div className={`${style.root}`}>


      <Link className={
        `${style.link
        }`
      }
        children={
          <svg
            width="34"
            height="35"
            viewBox="0 0 34 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12.3331 23.6245L20.4465 15.182L20.4465 22.9377L22.3331 22.9308V11.8313H11.6664L11.6664 13.7876L19.1131 13.7945L10.9997 22.237L12.3331 23.6245Z" fill="black" /></svg>
        } />


      {/* slider */}
      <Slider propsSetting={customSetting}>
      {/* removed offer banner [ offer completed ] */}
        <SwiperSlide>
          <div className={`${style.banner_wrapper} ${style.hideShadow}`}>
            <div className={style.image}>
              <img loading="lazy" src={offerImage} alt="banner" />
            </div>
          </div>
        </SwiperSlide>
        {data?.[0].data && data?.[0].data.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <div className={style.banner_wrapper}>

                <div className={style.image}>
                  <img loading="lazy" src={`${BASE_URL}/uploads/${item.image[0].filename}`} alt="banner" />
                </div>

                <div className={style.text_wrapper}>
                  <div className={style.title}>
                    <h2 className={`secondary-font ttl-1`}>{item.title}</h2>
                  </div>
                  <div className={style.button}>
                    <Link className={`custom_btn light`} to={item.link_url}>
                      <span>{item.link_text}</span>
                      <span><Icon icon={'arrow'} color={'#fff'} size={14} /></span>
                    </Link>
                  </div>

                </div>

              </div>
            </SwiperSlide>
          )

        })
        } </Slider>

    </div>
  )
}

export default Banner
